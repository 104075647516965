import { Box, Button, HStack } from '@chakra-ui/react'
import { components } from '@reactour/tour'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'

type NavigationComponent = typeof components.Navigation
const TourNavigation: NavigationComponent = ({
  setIsOpen,
  setCurrentStep,
  steps,
  currentStep,
}) => {
  const isFirstStep = currentStep === 0
  const isLastStep = currentStep === steps.length - 1

  const handleFinishTour = () => {
    localStorage.setItem('isTourComplete', 'true')
    setIsOpen(false)
  }

  return (
    <HStack marginTop={4} justifyContent='space-between'>
      {!isLastStep && (
        <Button onClick={handleFinishTour} variant='ghost'>
          Skip the Tour
        </Button>
      )}
      <HStack width='195px' justifyContent='center'>
        {steps.map((_, index) => (
          <Box
            boxSize='10px'
            backgroundColor={index === currentStep ? 'iris.500' : 'iris.200'}
            borderRadius='full'
            key={index}
          />
        ))}
      </HStack>
      <HStack>
        {!isFirstStep && (
          <Button
            leftIcon={<BiLeftArrowAlt />}
            variant='outline'
            onClick={() => setCurrentStep((step: number) => step - 1)}
          >
            Back
          </Button>
        )}
        {!isLastStep && (
          <Button
            rightIcon={<BiRightArrowAlt />}
            onClick={() => setCurrentStep((step: number) => step + 1)}
          >
            Next
          </Button>
        )}
        {isLastStep && (
          <Button onClick={handleFinishTour}>Begin using Vantage</Button>
        )}
      </HStack>
    </HStack>
  )
}
export default TourNavigation
