import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import '@fontsource/montserrat'
import '@fontsource/open-sans'
import { TourProvider } from '@reactour/tour'

import { TourNavigation } from '@/components/atoms'
import Guardian from '@/components/organisms/Guardian'
import theme from '@/theme'
import tourProviderStyles from '@/theme/tourProviderStyles'
import welcomeWalkthroughSteps from '@/tourSteps/welcomeWalkthrough'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <TourProvider
          components={{ Navigation: TourNavigation }}
          // prevent the tour closing when clicking outside the popover
          onClickMask={() => null}
          showBadge={false}
          showCloseButton={false}
          steps={welcomeWalkthroughSteps}
          styles={tourProviderStyles}
        >
          <Guardian />
        </TourProvider>
      </ChakraProvider>
    </QueryClientProvider>
  )
}

export default App
